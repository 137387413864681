import React from 'react';
import { BsTwitter, BsInstagram,BsLinkedin,BsTelegram } from 'react-icons/bs';
import {RiWhatsappFill}from 'react-icons/ri';
import { FaFacebookF } from 'react-icons/fa';
import { FaTiktok } from "react-icons/fa";

const SocialMedia = () => (
  <div className="app__social">
     <div >
     <a href='https://www.facebook.com/ethiochinets'><FaFacebookF /></a> 
    </div>
    <div>
      <a href='https://www.linkedin.com/company/ethiochinet/?viewAsMember=true/'> <BsLinkedin /></a>
    </div>
   
    <div>
     <a href='https://t.me/ethiochinet_logistics'> <BsTelegram /></a> 
    </div>
    <div>
    <a href='https://www.tiktok.com/@ethiochinet'> <FaTiktok /></a> 
   
    </div>
  </div>
);

export default SocialMedia;