import Erotrucker from '../assets/Erotrucker.PNG';
import marskt from '../assets/marskt.png';
import ethiochinet from '../assets/ethiochinet.png';
import ethiochinet_tra from '../assets/ethiochinet_tra.png';
import get_it_onpalystore_2 from '../assets/get_it_onpalystore_2.png';
import get_it_onpalystore from '../assets/get_it_onpalystore.png';
import userimg from '../assets/userimg.png';
import ethiochinet_webcover from '../assets/ethiochinet_webcover.jpg';

export default  {
  Erotrucker,
  marskt,
  ethiochinet,
  ethiochinet_tra,
  get_it_onpalystore_2,
  get_it_onpalystore,
  userimg,
  ethiochinet_webcover
};