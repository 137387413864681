import React, { useState }  from 'react'

import {motion} from 'framer-motion';
import {AppWrap} from '../../wrapper';
import { images } from '../../constants';
import {FiDownload} from 'react-icons/fi'
// import {urlFor,client} from '../../client';

import './Work.scss';
const Work = () => {
  const [activeFilter,setactiveFilter]=useState('All');
  const [animateCard,setanimateCard]=useState({y:0,opacity:1});

  const works=[
    {title:'የኢትዮጭነት  የጭነት ባለቤቶች እና የጭነት አስተላላፊዎች  መተግበሪያ  ',description:'ለማዉረድ ይህን ይጫኑ ',imgUrl:images.chinetalle,codelink:'https://play.google.com/store/apps/details?id=com.ethiochinet.ethiochinet',srcimg:images.get_it_onpalystore_2},
    {title:'የኢትዮጭነት  የጭነት አሽከርካዎች መተግበሪያ ',description:'ለማዉረድ ይህን ይጫኑ ',imgUrl:images.asu,codelink:'https://play.google.com/store/apps/details?id=com.ethiochinet.ethiochinet_driver',srcimg:images.get_it_onpalystore},
   
  ]


  const handleWorkFilter=(item)=>{
        setactiveFilter(item);
        setanimateCard({y:100,opacity:0});
        setTimeout(() => {
          setanimateCard({y:0,opacity:1});
          if(item==='ALL'){
            setactiveFilter(works);
          }else{
            setactiveFilter(works.filter((work)=>work.tags.includes(item)))
          }
        }, 500);
  }
  return (
  <>
    <h2 className="head-text"> <span>የስልክ መተግበሪያዉን አዉርደዉ ይመዝገቡ ፣ይጠቀሙ   </span> </h2>
 
    <motion.div
    animate={animateCard}
    transition={{duration:0.5,delayChildren:0.5}}
    className="app__work-portfolio"
    >
      {works.map((work, index) => (
        <div className='app__work-item app__flex' key={index}>
            <div className='app__work-content app__flex'>
           <h4 className='bold-text'><a href={work.codelink}>{work.title}</a></h4>
         
          </div>
          <div className='app__work-img app__flex'>
    
          <a href={work.codelink} className='btnDownloads'>  <img src={work.srcimg} alt='Banner'/> </a>

          </div>
        
        </div>
       ))}
    </motion.div>
  </>
    
  )
}

export default AppWrap(Work,'የስልክ መተግበሪያዉን ለማዉረድ') ;