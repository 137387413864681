import React from 'react'
// import {Link} from 'react-router-dom';
import './Footer.scss';
 const Footer = () => {
  return (
    <div className='footerLogin'>
     <div id="copyright">
		  <p> &nbsp;Copyright &copy;  
		  <span className="color-a" >2022-2023
		</span> Ethiochinet Logistics . Powered By <a href="https://www.utopiatech.et" style={{color: 'white', textDecoration: 'none' }}> &nbsp;Utopia Technologies </a></p>
      </div>
    </div>
  )
}
export default Footer;