
import React, { useState }  from 'react'
import ReactTooltip from 'react-tooltip';
import {motion} from 'framer-motion';
import {AppWrap,MotionWrap } from '../../wrapper';
import { images } from '../../constants';


import './Skills.scss';
const Skills = () => {
  const skills=[
    {servicename:'የጭነት መረጃ አገልግሎት መስጠት ',description:'',bgColor:'',icon:''},
    {servicename:'ጭነትን ለማጓጓዝ ህጋዊ ወኪል መሆን',description:'',bgColor:'',icon:''},
    {servicename:'የጭነት አስተላላፊዎች በ አቀራቢያቸዉ ያለዉን የጭነት መኪና በቀላሉ አንዲያገኙ ማድረግ ',description:'',bgColor:'',icon:''},
    {servicename:'የጭነት መኪና አሽከርካሪዎች ጭነት በ አራገፉበት አካባቢ ጭነት በቀላሉ አንዲያገኙ ማድረግ   ',description:'',bgColor:'',icon:''},
    {servicename:'የጭነት አስተላላፊዎች/ደላላዎች   በ አቅራቢያቸዉ የሚገኝ አሽከርካሪ በ ቀላሉ እንዲያገኝ ማስቻል፡፡ ',description:'',bgColor:'',icon:''},
    
    
  ]
  return (
   <>
   <h2 className='head-text'>የምንሰጣቸዉ አገልግሎቶች እና ደንበኞች </h2>
   <div className='content_myservices'>
         <motion.div
         className='app__skills-list'
       
         >
          {skills.map((skill,index)=>(
              <motion.div
              whileInView={{opacity:[0,1]}}
              transition={{duration:0.5}}
              className="app__skills-item app__flex"
              key={skill.servicename}
              >
              <ul>
                <li>{skill.servicename}</li>
              </ul>
               </motion.div>
          ))}
          
         </motion.div>
   </div>
   </>
  )
}

export default AppWrap(
  MotionWrap(Skills, 'app__skills'),
  'አገልግሎቶች',
  'app__whitebg',
);