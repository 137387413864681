import React, { useState, useEffect } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
// import { urlFor, client } from '../../client';
import './Testimonial.scss';
import { images } from '../../constants';

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [testimonials, setTestimonials] = useState([]);
  const [brands, setBrands] = useState([]);

  const handleClick = (index) => {
    setCurrentIndex(index);
  };

  const testimonials=[
    {name:'Melkamu Almawu',company:'ሹፌር',feedback:'በ አሉበት ሁሉ ጭነት ለማግኘት ተመራጭ እና አስተማማኝ ሆኖ አግኝቸዋለሁ ',imgUrl:images.profile},
    {name:'Yimer Amedie',company:'የጭነት ባለቤት ',feedback:'ጭነቴን ማን እነደጫነዉ አና የት እነደደረሰ ሰለሚያሳየኝ ለኔ ተመራጭ አድረጌዋለሁ፡፡',imgUrl:images.profile},
    {name:'Misganaw Mheretu',company:'የመኪና ባለቤት ',feedback:'መኪናየ ካለ ጭነት እንዳየጓዝ በሄደበት ሁሉ ጭነት ጭኖ እነዲመለስ መተግበሪያዉ  እጅግ ተመራጭ ነዉ፡፡',imgUrl:images.profile},
    {name:'Abebe',company:'ሹፌር',feedback:'ሁሌም ከ እሁድ እስከ እሁድ መተግበያዉ ስለሚሰራ ተመችቶኛል፡፡',imgUrl:images.profile}
  ]
  const brand=[
    {name:'Melkamu Almawu',imgUrl:images.userimg},
    {name:'Yimer Amedie',imgUrl:images.userimg},
    {name:'Misganaw Mheretu',imgUrl:images.userimg},
   
  ]
  // useEffect(() => {
  //   const query = '*[_type == "testimonials"]';
  //   const brandsQuery = '*[_type == "brands"]';

  //   client.fetch(query).then((data) => {
  //     setTestimonials(data);
  //   });

  //   client.fetch(brandsQuery).then((data) => {
  //     setBrands(data);
  //   });
  // }, []);

  return (
    <>
      {testimonials.length && (
        <>
         <h2 className='head-text'>ደንበኞቻችን ምን አሉ  </h2>
          <div className="app__testimonial-item app__flex">

            <img src={images.userimg} alt={testimonials[currentIndex].name} />
            <div className="app__testimonial-content">
              <p className="p-text">{testimonials[currentIndex].feedback}</p>
              <div>
                {/* <h4 className="bold-text">{testimonials[currentIndex].name}</h4> */}
                <h5 className="p-text">{testimonials[currentIndex].company}</h5>
              </div>
            </div>
          </div>

          <div className="app__testimonial-btns app__flex">
            <div className="app__flex" onClick={() => handleClick(currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1)}>
              <HiChevronLeft />
            </div>

            <div className="app__flex" onClick={() => handleClick(currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1)}>
              <HiChevronRight />
            </div>
          </div>
        </>
      )}

      {/* <div className="app__testimonial-brands app__flex">
        {brands.map((brand) => (
          <motion.div
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 0.5, type: 'tween' }}
            key={brand._id}
          >
            <img src={(brand.imgUrl)} alt={brand.name} />
          </motion.div>
        ))}
      </div> */}
    </>
  );
};

export default AppWrap(
  MotionWrap(Testimonial, 'app__testimonial'),
  'testimonial',
  'app__primarybg',
);