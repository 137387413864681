import React from 'react'
import {motion} from 'framer-motion';
import {images} from '../../constants';
import { AppWrap } from '../../wrapper';

import './Header.scss';

const scaleVariant={
  whileInView:{
    scale:[0,1],
    opacity:[0,1],
    transition:{
      duration:1,
      ease:'easeInOut'
    }
  }
}
const Header = () => {

  return (
    <div className=''>
       
        <h2>About Us</h2>

    </div>
  )
}

export default AppWrap(Header,'home') ;