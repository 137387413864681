import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { images } from '../../constants';
import { AppWrap,MotionWrap } from '../../wrapper';

import './About.scss';
// import { urlFor, client } from '../../client';

const About = () => {
  // const [abouts, setAbouts] = useState([]);

const abouts=[
  {title:'Web Development',description:'I Full Stack Web Developer',imgUrl:images.webdev},
  {title:'Mobile App Development',description:'I am senior Flutter Mobile Developer',imgUrl:images.flutterapp},
  {title:'Digital Marketing',description:'I make the your bussines digital ',imgUrl:images.digitalmarketing},
  {title:'Consultancy',description:'I consult tech related for any company',imgUrl:images.consultancy}
]

  return (
    <>
      <h2 className="head-text"><span> ስለ እኛ</span>  </h2>

      <div className="app__profiles">
       
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="app__profile-item"
            
          >
            <div>
            <p style={{justifyContent:'space-between'}}>ኢትዮጭነት ሎጅስቲክስ - የጭነት ሎጅስቲክስ ቴክኖሎጂ ሲሆን በቴክኖሎጂ በመታገዝ በመላ ኢትዮጲያ የሚገኙትን ጥሬ እቃዎች ወይም የተለያዪ የፍብሪካ እና ግብርና ምርቶች እንዲጓዙ የጭነት ባለቤቶችንና እና የጭነት አሽከርካሪዎችን በአንድ ሲስተም በ ማገናኘት ጭነቱን ሙሉ ሀፊነት በመዉሰድ የሚያጓጉዝ ድርጅት ነዉ፡፡
            ይህንንም ለማሳለጥ የስልክ መተግበሪያ ሶፍተዌር በማልማት ለሁሉም የመንግስት ድርጅቶች ፣መንግስታዊ ያልሆኑ ድርጅቶች ፣የግል ድርጅቶች፣ካምፓኒዎች፣ጭነት አስተላላፊዎች እና ለማንኛዉም ደንበኞች ጭነት ሲኖራቸዉ  የጭነት መረጃቸዉን ወደ
             እኛ ሲስተም በመመዝገብ  የጭነት መረጃቸዉ በእኛ ወይም በጭነት 
            አሽከርካሪዎች እና በጭነት አስተላላፊዎች(ደላላዎች) በቀላሉ ተደራሽ እና እንዲታይ በማድረግ ጭነትዎ በቶሎ እንዲገጓጓዝ የሚያስችል የስልከ መተግበሪያ አቅርበንልወታል፡፡
            </p><br />
            </div>
          
           <div>
           <p>Ethiochinet Logistics is a freight logistics technology solution for transporting goods /or products across the country by taking full responsibility 
              for freight. We presented a platform for any government organization, non-government organization,
               private organizations, companies, freight forwarders, and any customer that wants to
                transport their goods/products in order to register freight information /or freight bid information to the system to make the freight information easily accessible 
                and viewed by ours or freight vehicle driver and freight forwarder to transport freight as soon. Ethiochinet Logistics  holds different Ethiopian freight information in one system.</p>
          
           </div>
          
          </motion.div>
     
      </div>
    </>
  );
};

export default AppWrap(About,'ስለ አኛ');
