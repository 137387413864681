import React from 'react'
import {About,Contactus,Footer,Header,Skills,Testimonial, Work} from './container';
import { Navbar } from './components';
import './App.scss';
const App = () => {
  return (
    <div className='app'>
      <Navbar/>
    
      <About/>
      <Work/>
      <Skills/>
      <Testimonial/>
      <Contactus/>
      <Footer/>
    </div>
  )

}
export default App;