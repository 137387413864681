import React, { useState } from 'react'
import './Home.css';


const Home = () => {
    const [loading, setloading] = useState(false);
  return (
    <div>
    <h1>List of Freights</h1>
    <div className="content">
  
  
  {loading ? ("Loading the Data .....") 
  : (
    <>
    
      
 
             <div className="question-section">
          <div className="question-count">
         
          </div>
          <div className="question-text">
          
          
          </div>
        </div>
        <div className="answer-section ">
         
           
          <div className="actions">
            {/* <button>Quit</button> */}
            <button>Next</button>
          </div>
        </div>
        
   
      
     
      </>
    
  )
  
  }
   
  </div>
  </div>
  )
}

export default Home;